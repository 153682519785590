<template>
  <div
    class="header"
  >
    <b-row class="text-right actions match-height">
      <b-col v-if="proposalCanBeAdded && !proposalAdded && ability.cannot('update', point) && ability.cannot('manage', point)">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          @click="handleAddProposalActionClick(point)"
        >
          <feather-icon
            icon="MessageSquareIcon"
            class="mr-25 d-none d-sm-inline"
          />
          <span>{{ $tc('Apply Now',point.id) }}</span>
        </b-button>
      </b-col>

      <b-col v-if="proposalAdded && ability.cannot('update', point)">
        <b-button
          variant="success"
          disabled
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-50 d-none d-sm-inline"
          />
          <span class="align-middle text-uppercase">{{ this.$t('proposal added') }}</span>
        </b-button>

      </b-col>
      <!--      <b-col v-if="point.post_status ==='closed'">-->
      <!--        <b-button-->
      <!--          :variant="`flat-${resolveCloseReasonColorVariant(point.close_reason)}`"-->

      <!--          disabled-->
      <!--        >-->
      <!--          <feather-icon-->
      <!--            icon="BookIcon"-->
      <!--            class="mr-50 d-none d-sm-inline"-->
      <!--          />-->
      <!--          <span class="align-middle">{{ this.$t('closed').toUpperCase() }}<small> ( {{ getCloseReasonTitle(point.close_reason) }} )</small></span>-->
      <!--        </b-button>-->

      <!--          <span-->
      <!--            class="pr-1"-->
      <!--          >-->
      <!--            <b-avatar-->
      <!--              rounded-->
      <!--              :variant="`light-${resolveCloseReasonColorVariant(point.close_reason)}`"-->
      <!--              size="36"-->
      <!--            >-->
      <!--              <feather-icon-->
      <!--                icon="CheckSquareIcon"-->
      <!--                size="24"-->
      <!--              />-->
      <!--            </b-avatar>-->
      <!--            <span-->
      <!--              class="p-1"-->
      <!--              :class="`text-${resolveCloseReasonColorVariant(point.close_reason)}`"-->
      <!--            >{{ this.$t('closed').toUpperCase() }}</span>-->

      <!--            <small>( {{ getCloseReasonTitle(point.close_reason) }})</small>-->
      <!--          </span>-->
      <!--      </b-col>-->
      <b-col v-if="point.post_status !=='closed' && ability.can('manage', 'ServiceRequest')">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="$router.push({ name: 'apps-service-providers-search' })"
        >
          <feather-icon
            icon="SearchIcon"
            class="mr-50 d-none d-sm-inline"
          />
          <span class="align-middle">{{ $t('find_a_contractors') }}</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Modal Complete Request -->
    <b-modal
      id="modal-close-request"
      ref="modal-close-request"
      v-model="displayModalCloseRequest"
      centered
      :static="true"
    >
      <!-- Modal Form Header -->
      <template #modal-header>

        <span class="h4">{{ $t('Please, select a closing reason') }}:</span>
        <b-button
          class="close btn-icon"
          variant="outline-secondary"
          size="sm"
          @click="displayModalCloseRequest=false"
        >
          <feather-icon
            icon="XIcon"
            size="26"
          />
        </b-button>
      </template>
      <!-- Modal Form Body -->
      <b-container>
        <div
          class="my-1 d-flex justify-content-center align-items-center"
        >
          <b-button
            v-for="(reason,key) in serviceCloseReasons"
            :key="key"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            pill
            class="m-25"
            :pressed="closeReason===key"
            @click="closeReason=key"
          >
            {{ $t(reason) }}
          </b-button>
        </div>

        <div v-if="(eventScheduled)">
          <b-alert
            variant="warning"
            show
          >
            <h4 class="alert-heading">
              {{ $t('message.warning') }}!
            </h4>
            <div
              class="alert-body"
            >
              <p>{{ $t('You have an appointment regarding the request') }}</p>
              <div class="d-flex justify-content-start align-items-center mb-75">
                <b-avatar
                  rounded
                  size="32"
                  class="mr-75"
                  variant="light-warning"
                >
                  <feather-icon
                    icon="CalendarIcon"
                    size="18"
                  />
                </b-avatar>
                <div class="font-weight-bold font-medium-4">
                  {{ eventDate| formatDateTime('DD.MM.YYYY') }}
                </div>
              </div>
              <div class="d-flex justify-content-start align-items-center mb-75">
                <b-avatar
                  rounded
                  size="32"
                  class="mr-75"
                  variant="light-warning"
                >
                  <feather-icon
                    icon="ClockIcon"
                    size="18"
                  />
                </b-avatar>
                <div class="font-weight-bold font-medium-4">
                  {{ eventDate | formatDateTime('HH:MM') }}
                </div>
              </div>
              {{ $t('Declining an accepted proposal may negatively affect your rating') }}! &#128533;
            </div>
          </b-alert>

        </div>
      </b-container>
      <!-- Modal Form Footer -->
      <template #modal-footer>
        <b-container fluid>
          <b-row class="d-flex justify-content-start align-items-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              block
              :disabled="!closeReason"
              @click="handleCloseRequestAction(point)"
            >
              <div
                v-if="submittingFetch"
                class="align-middle"
              >
                <b-spinner
                  type="grow"
                  small
                />
                <span class="align-middle"> {{ $t('Loading...') }}</span>
              </div>
              <div v-else>
                <feather-icon
                  icon="BookIcon"
                  class="mr-50"
                />
                <span class="align-middle">{{ $t('Close Request') }}</span>
              </div>
            </b-button>
          </b-row>
        </b-container>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BAvatar,
  BContainer,
  BModal,
  BSpinner,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import router from '@/router'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import { usePointRemoteData, usePointsUi } from '@/views/apps/service/usePoints'
import { manageProposalActions, useEventsUI, useProposalRemoteData } from '@/views/apps/service/proposal/useProposals'
import { serviceOptions } from '@/mixins/options'
import { showToast, customToast } from '@/mixins/notification/toasts'
import { formatDateTime } from '@/filters/dateTime'
import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter'
import { ref, computed } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BAvatar,
    BContainer,
    BModal,
    BSpinner,
    BAlert,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatDateTime,
  },
  mixins: [showToast],
  props: {
    proposalCanBeAdded: {
      type: Boolean,
      default: false,
    },
    proposalAdded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    point() {
      return store.getters['point/Point']
    },

  },
  created() {
    GlobalEventEmitter.$on('close-request', data => {
      this.closeReason = data.close_reason
      this.closeRequest(this.point)
    })
  },
  methods: {
    deleteRequest(point) {
      store.dispatch('point/deleteServiceRequest', { id: point.id }).then(response => {
        if ([200, 201, 'success'].includes(response.status)) {
          this.showToast(response, 'Request')
          // GlobalEventEmitter.$emit('requestDeleted', point)
          router.push({ name: 'apps-service-my-requests' })
        }
      })
    },

  },
  setup() {
    const displayDeclineProposalAlert = ref(false)
    const submittingFetch = ref(false)
    const displayModalClose = ref(false)
    const displayModalCloseRequest = ref(false)
    const closeReason = ref('')
    const ability = defineAbilityForCurrentUser()
    const { eventAccepted } = useEventsUI()
    const { handleAddProposalActionClick } = manageProposalActions()
    const { closeServiceRequest } = usePointRemoteData()
    const { resolveCloseReasonColorVariant, isManaged, handleEditRequestActionClick } = usePointsUi()
    const { getServiceCloseReasons, getServiceCompleteReason } = serviceOptions
    const {
      declineProposal,
    } = useProposalRemoteData()

    const acceptedProposal = computed(() => {
      const proposals = store.getters['proposal/proposals']
      return proposals.find(proposal => eventAccepted(proposal))
    })

    const eventDate = computed(() => {
      if (!acceptedProposal.value) return false
      return acceptedProposal.value.extendedProps.appointment
    })

    const eventScheduled = computed(() => eventDate.value && (new Date().toISOString() < eventDate.value))

    const eventPassed = computed(() => eventDate.value && (new Date().toISOString() >= eventDate.value))

    const handleCloseRequestResponse = (response, payload) => {
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        store.commit('point/setPoint', { ...store.getters['point/Point'], ...response.data.data })
        GlobalEventEmitter.$emit('request-closed', payload)
        GlobalEventEmitter.$emit('showCustomToast', response, null, null, 'Request Closed')
      } else {
        GlobalEventEmitter.$emit('showCustomToast', response, 'Request')
      }
    }

    function closeRequest(point) {
      submittingFetch.value = true
      const payload = { id: point.id, close_reason: closeReason.value }
      closeServiceRequest(payload)
        .then(response => {
          displayModalCloseRequest.value = false
          handleCloseRequestResponse(response, payload)
        })
        .finally(() => { submittingFetch.value = false })
    }

    function completeRequest(point) {
      submittingFetch.value = true
      const payload = { id: point.id, close_reason: closeReason.value }
      closeServiceRequest(payload)
        .then(response => {
          displayModalCloseRequest.value = false
          handleCloseRequestResponse(response, payload)
        })
        .finally(() => { submittingFetch.value = false })
    }

    function declineProposalAndCloseRequest(point) {
      submittingFetch.value = true
      const payload = {
        id: acceptedProposal.value.id,
        decline_reason: closeReason.value,
      }
      declineProposal(payload).then(response => {
        const successStatuses = [200, 201]
        if (successStatuses.includes(response.status)) {
          GlobalEventEmitter.$emit('showCustomToast', response, null, null, 'Proposal Declined')
          closeRequest(point)
        } else {
          GlobalEventEmitter.$emit('showCustomToast', response, 'Proposal')
        }
      }).finally(() => { submittingFetch.value = false })
    }

    function handleCloseRequestAction(point) {
      if (!eventDate.value) {
        return closeRequest(point)
      }
      if (eventScheduled.value) {
        return declineProposalAndCloseRequest(point)
      }

      return completeRequest(point)
    }

    const serviceCloseReasons = computed(() => {
      if (!eventDate.value || eventScheduled.value) {
        return getServiceCloseReasons()
      }
      return getServiceCompleteReason()
    })
    return {
      ability,
      submittingFetch,
      closeServiceRequest,
      resolveCloseReasonColorVariant,
      isManaged,
      getServiceCloseReasons,
      getServiceCompleteReason,
      // poposals
      eventAccepted,
      handleAddProposalActionClick,
      handleEditRequestActionClick,
      displayModalClose,
      closeReason,
      displayModalCloseRequest,
      eventScheduled,
      eventPassed,
      eventDate,
      acceptedProposal,
      closeRequest,
      completeRequest,
      displayDeclineProposalAlert,
      handleCloseRequestAction,
      serviceCloseReasons,
    }
  },
}

</script>
<style scoped>
  .actions .btn {
    height: 100%;
    margin-bottom: 1rem;
  }

</style>
